<template>
  <section class="pt:4 bg-white md:pt-16">
    <div class="mx-auto grid max-w-screen-xl grid-cols-1 px-4 py-8 sm:py-16 lg:grid-cols-3 lg:gap-8 lg:px-6">
      <div class="flex flex-col gap-8 text-center sm:grid-cols-2 lg:grid-cols-1">
        <div>
          <h2 class="text-start text-3xl">
            {{ data.title }}
          </h2>
        </div>
        <div class="mb-10 flex flex-col gap-4 lg:mb-0">
          <SharedListItem v-for="item in data.listItems" :key="item.id" :data="item" />
        </div>
      </div>
      <div class="col-span-2 mb-8 lg:mb-0">
        <form class="mx-auto grid max-w-screen-md grid-cols-1 gap-8 sm:grid-cols-2" @submit.prevent="submit">
          <div class="relative">
            <input
              id="firstName"
              v-model.trim="firstName"
              type="text"
              class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
              placeholder=" " />
            <label
              for="firstName"
              class="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
              >Vorname</label
            >
          </div>

          <div class="relative">
            <input
              id="lastName"
              v-model.trim="lastName"
              type="text"
              class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
              placeholder=" "
              required />
            <label
              for="lastName"
              class="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
              >Nachname*</label
            >
          </div>

          <div class="relative">
            <input
              id="email"
              v-model.trim="email"
              type="email"
              class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
              placeholder=" "
              required />
            <label
              for="email"
              class="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
              >E-Mail-Adresse*</label
            >
          </div>

          <div class="relative">
            <input
              id="phone"
              v-model.trim="phone"
              type="text"
              class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
              placeholder=" " />
            <label
              for="phone"
              class="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
              >Telefonnummer</label
            >
          </div>

          <div class="relative sm:col-span-2">
            <textarea
              id="message"
              v-model.trim="message"
              rows="6"
              class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
              placeholder=" "
              required />
            <label
              for="message"
              class="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-6 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
              >Worüber hättest du gerne mehr Informationen?*</label
            >
            <div v-if="successMsg" class="my-4 rounded-lg bg-green-50 p-4 text-sm text-green-800" role="alert">
              Vielen Dank für die Nachricht! Wir melden uns so bald wie möglich.
            </div>
            <div v-if="errorMsg" class="my-4 rounded-lg bg-red-50 p-4 text-sm text-red-800" role="alert">
              Ups, beim Versand ist etwas schiefgelaufen. Bitte versuch es später noch einmal.
            </div>
            <p class="mt-4 text-sm text-gray-500">
              Durch Klicken auf
              <span class="font-medium">"Kontaktiere uns"</span> erkläre ich mich mit den
              <NuxtLink
                to="/agb"
                no-rel="no-rel"
                no-prefetch="no-prefetch"
                class="dark:text-primary-500 text-gray-900 hover:underline"
                target="_blank"
                >Allgemeinen Geschäftsbedingungen</NuxtLink
              >
              sowie mit der Verarbeitung meiner persönlichen Daten gemäß der
              <NuxtLink
                to="/datenschutz"
                no-rel="no-rel"
                no-prefetch="no-prefetch"
                class="dark:text-primary-500 text-gray-900 hover:underline"
                target="_blank"
                >Datenschutzvereinbarung</NuxtLink
              >
              einverstanden.
            </p>
          </div>
          <button
            type="submit"
            :disabled="isSubmitting"
            class="flex w-max min-w-[240px] items-center justify-center gap-3 rounded-full bg-gray-900 px-4 py-2 font-medium text-gray-100 transition hover:bg-gray-300 hover:text-gray-900 disabled:cursor-not-allowed disabled:bg-gray-900 disabled:text-gray-100">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.8937 3.15264C10.8106 2.98676 10.6829 2.84728 10.525 2.74981C10.3671 2.65233 10.1853 2.60071 9.9997 2.60071C9.81415 2.60071 9.63226 2.65233 9.47438 2.74981C9.31649 2.84728 9.18884 2.98676 9.1057 3.15264L2.1057 17.1526C2.01885 17.3262 1.98439 17.5213 2.00653 17.7142C2.02867 17.907 2.10645 18.0892 2.23039 18.2386C2.35433 18.388 2.51907 18.4981 2.70451 18.5555C2.88994 18.6128 3.08806 18.615 3.2747 18.5616L8.2747 17.1326C8.48368 17.0729 8.66749 16.9466 8.79831 16.7731C8.92912 16.5995 8.99982 16.388 8.9997 16.1706V11.5996C8.9997 11.3344 9.10506 11.0801 9.2926 10.8925C9.48013 10.705 9.73449 10.5996 9.9997 10.5996C10.2649 10.5996 10.5193 10.705 10.7068 10.8925C10.8943 11.0801 10.9997 11.3344 10.9997 11.5996V16.1706C10.9996 16.388 11.0703 16.5995 11.2011 16.7731C11.3319 16.9466 11.5157 17.0729 11.7247 17.1326L16.7247 18.5606C16.9113 18.6141 17.1094 18.612 17.2948 18.5548C17.4803 18.4976 17.6451 18.3877 17.7691 18.2384C17.8931 18.0891 17.9711 17.907 17.9934 17.7142C18.0157 17.5214 17.9814 17.3263 17.8947 17.1526L10.8947 3.15264H10.8937Z"
                fill="currentColor" />
            </svg>

            Kontaktiere uns
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref } from "vue";
const config = useRuntimeConfig();
const successMsg = ref(false);
const errorMsg = ref(false);

const props = defineProps({
  data: Object,
});

const firstName = ref("");
const lastName = ref("");
const email = ref("");
const phone = ref("");
const message = ref("");

const isSubmitting = ref(false);

async function submit() {
  isSubmitting.value = true;

  try {
    await $fetch(`${config.public.apiUrl}/api/contact-messages`, {
      body: {
        data: {
          email: email.value,
          firstName: firstName.value,
          form: {
            type: props.data?.form.type,
          },
          lastName: lastName.value,
          message: message.value,
          phone: phone.value,
        },
      },
      method: "POST",
    });

    // show success & hide error message
    successMsg.value = true;
    errorMsg.value = false;

    // re-enable submit button
    isSubmitting.value = false;

    // reset form
    firstName.value = "";
    lastName.value = "";
    email.value = "";
    phone.value = "";
    message.value = "";
  } catch (error) {
    errorMsg.value = true;
    isSubmitting.value = false;
  }
}
</script>

<style lang="scss" scoped></style>
